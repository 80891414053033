export const getUserCosmos = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return {};
  }

  return {
    name: userData?.name,
    org_code: userData?.orgCode,
    org_id: userData?.orgId,
    phone: userData?.mobile,
    source_user_id: userData?.id ? userData.id + "" : undefined, // BE expects source_user_id as STRING, converting to string
    user_type: userData?.userType,
    premium_status: +userData?.isTutorPremium,
    email: userData?.email,
    premium_expiry: userData?.premiumExpiry,
    // "org_created_date": "2021-01-01",
    country_code: userData?.countryCode,
    is_userid_even: userData?.id ? userData.id % 2 === 0 : undefined,
    // "is_renewed": true,
    // "category": "A",
    // "cohort": "Jan22",
    // "is_blacklisted": 0,
    // "is_store": 1,
  };
};
